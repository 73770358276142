// @flow
import React from 'react';
import {action, autorun, observable} from 'mobx';
import {observer} from 'mobx-react';
import {List, ListStore, SingleListOverlay, withToolbar} from 'sulu-admin-bundle/containers';
import {translate} from 'sulu-admin-bundle/utils';
import MediaCollection from 'sulu-media-bundle/containers/MediaCollection';
import CollectionStore from 'sulu-media-bundle/stores/CollectionStore';
import mediaOverviewStyles from 'sulu-media-bundle/views/MediaOverview/mediaOverview.scss';
import type {ViewProps} from 'sulu-admin-bundle/containers';
import type {IObservableValue} from 'mobx/lib/mobx';
import type {ElementRef} from 'react';
import {ResourceRequester} from 'sulu-admin-bundle/services';
import "./mediaOverview.css";
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/dist/themes/ui-darkness/jquery-ui.css';
import $ from "jquery";

import Masonry from 'masonry-layout/dist/masonry.pkgd';
import jQueryBridget from 'jquery-bridget';
import imagesLoaded from 'imagesloaded/imagesloaded.pkgd';

const COLLECTION_ROUTE = 'app.media.overview';
const MEDIA_ROUTE = 'sulu_media.form.details';

const COLLECTIONS_RESOURCE_KEY = 'collections';
const MEDIA_RESOURCE_KEY = 'media';

const USER_SETTINGS_KEY = 'media_overview';
let param = "";

jQueryBridget('masonry', Masonry, $);
jQueryBridget('imagesLoaded', imagesLoaded, $);

@observer
class mediaOverview extends React.Component<ViewProps> {
    collectionPage: IObservableValue<number> = observable.box();
    mediaPage: IObservableValue<number> = observable.box();
    locale: IObservableValue<string> = observable.box();
    collectionId: IObservableValue<?number | string> = observable.box();
    @observable errors: Array<string> = [];
    @observable mediaListStore: ListStore;
    @observable collectionListStore: ListStore;
    @observable collectionStore: CollectionStore;
    mediaList: ?ElementRef<typeof List>;
    @observable showMediaMoveOverlay: boolean = false;
    @observable showMediaUploadOverlay: boolean = false;
    @observable mediaMoving: boolean = false;
    disposer: () => void;

    @observable page: number = 1;
    @observable term: string = "";
    @observable age: string = "";
    @observable ethnicity: string = "";
    @observable color: string = "";
    @observable sex: string = "";
    @observable license: string = "";
    @observable saveAsTag: string = "";

    static remountViewOnLogin = true;

    static getDerivedRouteAttributes() {
        return {
            collectionLimit: ListStore.getLimitSetting(COLLECTIONS_RESOURCE_KEY, USER_SETTINGS_KEY),
            mediaFilter: ListStore.getFilterSetting(MEDIA_RESOURCE_KEY, USER_SETTINGS_KEY),
            mediaLimit: ListStore.getLimitSetting(MEDIA_RESOURCE_KEY, USER_SETTINGS_KEY),
            mediaSortColumn: ListStore.getSortColumnSetting(MEDIA_RESOURCE_KEY, USER_SETTINGS_KEY),
            mediaSortOrder: ListStore.getSortOrderSetting(MEDIA_RESOURCE_KEY, USER_SETTINGS_KEY),
        };
    }

    constructor(props: ViewProps) {
        super(props);

        const {router} = this.props;

        this.mediaPage.set(1);

        router.bind('collectionPage', this.collectionPage, 1);
        router.bind('mediaPage', this.mediaPage, 1);
        router.bind('locale', this.locale);
        router.bind('id', this.collectionId);

        this.disposer = autorun(this.createCollectionStore);

        this.createCollectionListStore();
        this.createMediaListStore();

        router.bind('search', this.mediaListStore.searchTerm);
        router.bind('collectionLimit', this.collectionListStore.limit, 10);
        router.bind('mediaFilter', this.mediaListStore.filterOptions, {});
        router.bind('mediaLimit', this.mediaListStore.limit, 10);
        router.bind('mediaSortColumn', this.mediaListStore.sortColumn);
        router.bind('mediaSortOrder', this.mediaListStore.sortOrder);
    }

    componentWillUnmount() {
        this.mediaListStore.destroy();
        this.collectionListStore.destroy();
        this.collectionStore.destroy();
        this.disposer();
    }

    createCollectionStore = () => {
        this.setCollectionStore(new CollectionStore(this.collectionId.get(), this.locale));
    };

    @action setCollectionStore(collectionStore: CollectionStore) {
        if (this.collectionStore) {
            this.collectionStore.destroy();
        }

        this.collectionStore = collectionStore;
    }

    createCollectionListStore = () => {
        this.collectionListStore = new ListStore(
            COLLECTIONS_RESOURCE_KEY,
            COLLECTIONS_RESOURCE_KEY,
            USER_SETTINGS_KEY,
            {
                page: this.collectionPage,
                locale: this.locale,
                parentId: this.collectionId,
            }
        );

        this.collectionListStore.sort('title', 'asc');
    };

    createMediaListStore() {
        const options = {};

        options.fields = [
            'id',
            'type',
            'name',
            'size',
            'title',
            'mimeType',
            'subVersion',
            'thumbnails',
        ].join(',');

        this.mediaListStore = new ListStore(
            MEDIA_RESOURCE_KEY,
            MEDIA_RESOURCE_KEY,
            USER_SETTINGS_KEY,
            {
                page: this.mediaPage,
                locale: this.locale,
                collection: this.collectionId,
            },
            options
        );
    }

    clearLists() {
        this.mediaListStore.clear();
        this.mediaListStore.clearSelection();
        this.collectionListStore.clear();
        this.collectionListStore.clearSelection();
    }

    @action handleCollectionNavigate = (collectionId: ?string | number) => {
        this.clearLists();
        this.mediaPage.set(1);
        this.collectionPage.set(1);
        this.collectionId.set(collectionId);
    };

    @action handleUploadError = (errors: Array<Object>) => {
        if (errors.length === 1) {
            this.errors.push(errors[0].detail || errors[0].title || translate('sulu_media.upload_server_error'));
        } else {
            this.errors.push(translate('sulu_media.upload_server_error'));
        }
    };

    @action handleUploadOverlayOpen = () => {
        this.showMediaUploadOverlay = true;
    };

    @action handleUploadOverlayClose = () => {
        this.showMediaUploadOverlay = false;
    };

    handleMediaNavigate = (mediaId: string | number) => {
        const {router} = this.props;
        router.navigate(
            MEDIA_ROUTE,
            {
                id: mediaId,
                locale: this.locale.get(),
            }
        );
    };

    setMediaListRef = (mediaList: ?ElementRef<typeof List>) => {
        this.appMediaList = mediaList;
    };

    @action handleMoveMediaOverlayClose = () => {
        this.showMediaMoveOverlay = false;
    };

    @action handleMoveMediaOverlayConfirm = (collection: Object) => {
        this.mediaMoving = true;

        this.mediaListStore.moveSelection(collection.id).then(action(() => {
            this.collectionListStore.reload();
            this.showMediaMoveOverlay = false;
            this.mediaMoving = false;
        }));
    };

    @action handleDeleteError = (error?: Object): void => {
        const message = error?.detail || error?.title || translate('sulu_admin.unexpected_delete_server_error');

        this.errors.push(message);
    };

    @action handleSave = () => {
        const {
            route: {
                options: {
                    success_view: successView
                },
            },
        } = this.props.router;
        const url = new URL(window.location.href);
        let locale = this.locale;

        let tags = encodeURI($('input[name=saveAsTag]').val());
        let saveAsTag = false;
        if (tags.length > 0) {
            saveAsTag = true;
        }
        let images = [];
        $('.freepik-image-thumbnail-checkbox').each(function (index) {
            if (this.checked) {
                if (tags.length > 0) {
                    images.push({
                        src: $(this).next().get(0).src,
                        FreepikHtml: $(this).next().get(0).alt,
                        filename: $(this).next().attr('data-filename'),
                        license: $(this).next().attr('data-licenses'),
                        titel: $(this).next().attr('title'),
                        id: $(this).next().attr('data-id'),
                        product: $(this).next().attr('data-products'),
                        tag: tags,
                        saveAsTag: saveAsTag,
                    });
                } else {
                    images.push({
                        src: $(this).next().get(0).src,
                        FreepikHtml: $(this).next().get(0).alt,
                        filename: $(this).next().attr('data-filename'),
                        license: $(this).next().attr('data-licenses'),
                        titel: $(this).next().attr('title'),
                        id: $(this).next().attr('data-id'),
                        product: $(this).next().attr('data-products'),
                        saveAsTag: saveAsTag,
                    });
                }
            }

        });

        let element = this;
        $('.backdrop').get(0).classList.remove('d-none');
        $('.loader').get(0).classList.remove('d-none');
        ResourceRequester
            .post("media", {
                images: images,
            }, {
                action: 'freePikSave',
                locale: locale,
            }).then(action((response) => {
            $('.loaded-freepik-container').children().remove();
            $('.backdrop').get(0).classList.add('d-none');
            $('.loader').get(0).classList.add('d-none');
        })).then(action(() => {
            element.collectionListStore.reload();
            element.mediaListStore.reload();
            element.page = 0;

            url.searchParams.delete('searchFreepik');
            $('.countPicked').get(0).innerText = 0;
            window.history.pushState({}, document.title, window.location.pathname + '#/collections/de');
            let dialog = document.querySelector("dialog");
            dialog.close();
        }));
        // find limitation from Prod (sulu freepik) check logs??
    }

    @action loadFreepikAPI = () => {
        // freepik API Suche:
        let locale = this.locale;
        let imageContainer = document.querySelector('.loaded-freepik-container');
        let data = {};
        let page = this.page;
        let term = this.term;
        let age = this.age;
        let ethnicity = this.ethnicity;
        let color = this.color;
        let sex = this.sex;
        let people = this.people;
        let license = this.license;
        let saveAsTag = this.saveAsTag;
        data.age = "";
        data.ethnicity = "";
        $('.ageSelect').find("input").each(function () {
            if (this.checked) {
                data.age += this.value + ' ';
            }
        });
        $('.ethnicitySelect').find("input").each(function () {
            if (this.checked) {
                data.ethnicity += this.value + ' ';
            }
        });
        $('.colorSelect').find("input").each(function () {
            if (this.checked) {
                data.color = this.value;
            }
        });

        data.sex = $('.sexSelect').get(0).value;
        data.people = $('.peopleSelect').get(0).value;
        data.license = $('.licenseSelect').get(0).value;
        data.saveAsTag = $('input[name=saveAsTag]').get(0).value;
        data.term = $('.textInput[name="term"]').get(0).value;
        if (data.term === term && data.age === age && data.ethnicity === ethnicity && data.color === color &&
            data.sex === sex && data.people === people && data.license === license) {
            page++;
        } else {
            page = 1;
            if (imageContainer.children.length > 0) {
                while (imageContainer.children.length > 0) {
                    imageContainer.children[0].remove();
                    $('.countPicked').get(0).innerText = 0;
                }
            }
        }

        this.color = data.color;
        this.age = data.age;
        this.ethnicity = data.ethnicity;
        this.term = data.term;
        this.page = page;
        this.sex = data.sex;
        this.people = data.people;
        this.license = data.license;
        this.saveAsTag = data.saveAsTag;
        // let loadingButton = $(".loadMore");
        // if (loadingButton.length > 0) {
        //     loadingButton.get(0).remove();
        // }
        $('.grid').masonry({
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true
        });

        $('.backdrop').get(0).classList.remove('d-none');
        $('.loader').get(0).classList.remove('d-none');
        ResourceRequester
            .get("media", {
                action: 'freePik',
                color: this.color,
                age: this.age,
                ethnicity: this.ethnicity,
                term: this.term,
                page: this.page,
                sex: this.sex,
                people: this.people,
                license: this.license,
                locale: locale,
            }).then(action((response) => {
            if (imageContainer !== undefined && this.term !== data.term) {
                while (imageContainer.children.length > 0) {
                    imageContainer.children[0].remove();
                }
                $('.countPicked').get(0).innerText = 0;
            }

            if (response['resultSize'] > 0 && $(".noResult").length !== 0) {
                let div = document.createElement("div");
                div.classList.add('grid');
                div.innerHTML = response['data'];
                imageContainer.append(div);

            } else {
                if ($(".noResult").length === 0) {
                    let div = document.createElement("div");
                    div.classList.add('grid');
                    div.innerHTML = response['data'];
                    imageContainer.append(div);
                }
            }
            var elements = document.querySelectorAll('.grid');
            for (let i = 0; i < elements.length; i++) {
                const masonry = new Masonry(elements[i], {
                    itemSelector: '.grid-item',
                    columnWidth: '.grid-item',
                    percentPosition: true
                });
                imagesLoaded(elements[i]).on('always', function () {
                    masonry.layout();
                    masonry.reloadItems();
                });
            }


            $(".freepik-image-thumbnail").closest('label').off('click').on('click', function () {
                $(this).find("input").get(0).checked = !$(this).find("input").get(0).checked;
                $('.countPicked').get(0).innerText = $('.thumbnail').find('input:checked').length;
            });
            $('.loadMore').off('click').on('click', this.loadFreepikAPI);
            $('.backdrop').get(0).classList.add('d-none');
            $('.loader').get(0).classList.add('d-none');

            /**/
            // $(document).ready(imagePreview);
        }));
    }

    render() {
        return (
            <>
                <dialog id="my-dialog" role="dialog" aria-labelledby="my-dialog-heading">
                    <div className="dropzone-overlay" role="button">
                        <span aria-label="su-times" className="su-times clickable close-icon" role="button"
                              tabIndex="0"></span>
                        <div className={"form-control"}>
                            <h2 id="my-dialog-heading">{translate("app.freepik.title")}</h2>
                            <p className="button-row">
                                <button className={"btn"} name="search" onClick={this.loadFreepikAPI}
                                        type={"button"}>{translate("app.search")}</button>
                                <button className={"btn btn-success"} type={"button"} id={"saveImages"} name="ok"
                                        onClick={this.handleSave}>{translate("app.save")}</button>
                                <button className={"btn"} name="cancel">{translate("app.cancel")}</button>
                                <div className={'countHolder'}>
                                    <label>{translate('app.count')}</label>
                                    <div className={'countPicked'}>0</div>
                                </div>
                                <div className={"grid-sizer"}></div>
                            </p>
                        </div>
                        <div className="backdrop d-none">
                            <div className="loader d-none"></div>
                        </div>
                        <div className="drop-area" role="button" tabIndex="0">
                            <div className="freepik-container">
                                <div className={"loaded-freepik-container"} data-page="1" data-locale={this.locale}>
                                </div>
                            </div>
                            <div className={"button-container"}>
                                <button className="btn loadMore" type="button">Weitere Suchen</button>
                                <button className={'btn'} type={'button'} id="scrollButton" title="Go to top"><i
                                    className={"fa fa-arrow-up"}></i></button>
                            </div>
                        </div>
                    </div>
                </dialog>
                <MediaCollection
                    className={mediaOverviewStyles.mediaCollection}
                    collectionListStore={this.collectionListStore}
                    collectionStore={this.collectionStore}
                    hideUploadAction={true}
                    locale={this.locale}
                    mediaListAdapters={['table', 'media_card_overview']}
                    mediaListRef={this.setMediaListRef}
                    mediaListStore={this.mediaListStore}
                    onCollectionNavigate={this.handleCollectionNavigate}
                    onDeleteError={this.handleDeleteError}
                    onMediaNavigate={this.handleMediaNavigate}
                    onUploadError={this.handleUploadError}
                    onUploadOverlayClose={this.handleUploadOverlayClose}
                    onUploadOverlayOpen={this.handleUploadOverlayOpen}
                    uploadOverlayOpen={this.showMediaUploadOverlay}/>
                <SingleListOverlay
                    adapter="column_list"
                    clearSelectionOnClose={true}
                    confirmLoading={this.mediaMoving}
                    disabledIds={this.collectionStore.id ? [this.collectionStore.id] : []}
                    itemDisabledCondition="!!locked"
                    listKey={COLLECTIONS_RESOURCE_KEY}
                    locale={this.locale}
                    onClose={this.handleMoveMediaOverlayClose}
                    onConfirm={this.handleMoveMediaOverlayConfirm}
                    open={this.showMediaMoveOverlay}
                    resourceKey={COLLECTIONS_RESOURCE_KEY}
                    title={translate('sulu_media.move_media')}
                    excludedIds={""}/>
            </>
        );
    }
}

export default withToolbar(mediaOverview, function () {
    const errors = this.errors;
    const router = this.props.router;
    const loading = this.collectionListStore.loading || this.mediaListStore.loading;

    const {
        route: {
            options: {
                locales,
                permissions: {
                    add: routeAddPermission,
                    delete: routeDeletePermission,
                    edit: routeEditPermission,
                },
            },
        },
    } = this.props.router;

    const locale = locales
        ? {
            value: this.locale.get(),
            onChange: action((locale) => {
                this.locale.set(locale);
            }),
            options: locales.map((locale) => ({
                value: locale,
                label: locale,
            })),
        }
        : undefined;

    const items = [];

    items.push({
        icon: 'su-download',
        label: translate('media.freepik_title'),
        onClick: action(() => {
            let locale = this.locale.get();

            (function () {
                var backdrop;
                Array.prototype.slice.call(document.querySelectorAll("dialog"))
                    .forEach(function (dialog) {
                        var callBacks = {
                                cancel: function () {
                                },
                                ok: function () {
                                }
                            },
                            close = dialog.querySelector(".close-icon");
                        if (!dialog.close) {
                            dialog.close = function () {
                                if (dialog.hasAttribute("open")) {
                                    dialog.removeAttribute("open");
                                }
                                if (backdrop && backdrop.parentNode) {
                                    backdrop.parentNode.removeChild(backdrop);
                                }
                            }
                        }
                        if (!dialog.show) {
                            dialog.show = function () {
                                var closeButton = dialog.querySelector(".close");
                                dialog.setAttribute("open", "open");
                                // after displaying the dialog, focus the closeButton inside it
                                if (closeButton) {
                                    closeButton.focus();
                                }
                                if (!backdrop) {
                                    backdrop = document.createElement("div");
                                    backdrop.id = "backdrop";
                                }
                                document.body.appendChild(backdrop);
                            }
                        }
                        dialog.setCallback = function (key, f) {
                            callBacks[key] = f;
                        };
                        dialog.triggerCallback = function (key) {
                            if (typeof callBacks[key] == "function") {
                                callBacks[key]();
                            }
                        };
                        if (close) {
                            $(close).off('click').on("click", function () {
                                dialog.close();
                                $('.countPicked').get(0).innerText = 0;
                                dialog.triggerCallback("cancel");
                            });
                        }
                        // handle buttons for user input
                        ["cancel", "ok"].forEach(function (n) {
                            var button = dialog.querySelector('[name="' + n + '"]');
                            if (button) {
                                $(button).off('click').on("click", function () {
                                    if (n === 'cancel') {
                                        dialog.close();
                                    }

                                    dialog.triggerCallback(n);
                                });
                            }
                        });
                    });
                // ESC and ENTER closes open dialog and triggers corresponding callback
                document.addEventListener("keydown", function (event) {
                    var currentElement = event.target || event.soureElement,
                        prevent = (currentElement.tagName && currentElement.tagName.match(
                            /^button|input|select|textarea$/i));
                    Array.prototype.slice.call(document.querySelectorAll("dialog"))
                        .forEach(function (dialog) {
                            if (dialog.hasAttribute("open")) {
                                // ENTER
                                if (event.keyCode === 13 && !prevent) {
                                    setTimeout(function () {
                                        $('button[name="search"]').trigger('click')
                                    }, 50);
                                }
                                // ESC
                                if (event.keyCode == 27) {
                                    dialog.close();
                                    setTimeout(function () {
                                        dialog.triggerCallback("cancel");
                                    }, 50);
                                }
                            }
                        });
                }, true);
            }());

            window.myDialog = function (data, OK, cancel) {
                var dialog = document.querySelector("#my-dialog"),
                    buttonRow = document.querySelector("#my-dialog .button-row"),
                    heading = document.querySelector("#my-dialog-heading"),
                    element, p, prop;
                if (dialog && buttonRow) {
                    // Standard-Titel
                    if (heading) {
                        heading.textContent = "Eingabe";
                    }
                    // jedes <ul> und <p> entfernen, außer <p class="button-row">
                    Array.prototype.slice.call(dialog.querySelectorAll(
                        "ul, p:not(.button-row)"))
                        .forEach(function (p) {
                            p.parentNode.removeChild(p);
                        });
                    // Elemente erstellen und gegebenenfalls mit Inhalten befüllen
                    for (prop in data) {
                        // alles bekommt ein <p> drumherum
                        p = document.createElement("p");
                        buttonRow.parentNode.insertBefore(p, buttonRow);
                        // simple Textausgabe
                        if (data[prop].type && data[prop].type == "info") {
                            p.textContent = data[prop].text;
                        }
                        // anderer Titel
                        if (data[prop].type && data[prop].type == "title" && heading) {
                            heading.textContent = data[prop].text;
                            // neues <p> wird hierfür nicht benötigt
                            p.parentNode.removeChild(p);
                        }
                        // numerischer Wert
                        if (data[prop].type && data[prop].type == "number") {
                            // <label> als Kindelement für Beschriftung
                            p.appendChild(document.createElement("label"));
                            p.lastChild.appendChild(document.createTextNode(data[prop].text + " "));
                            // <input type="number">
                            element = p.appendChild(document.createElement("input"));
                            if (data[prop].hasOwnProperty("max")) {
                                element.max = data[prop]["max"];
                            }
                            if (data[prop].hasOwnProperty("min")) {
                                element.min = data[prop]["min"];
                            }
                            if (data[prop].hasOwnProperty("step")) {
                                element.step = data[prop]["step"];
                            }
                            element.classList.add('numberInput');
                            element.name = prop;
                            element.type = "number";
                            element.value = element.min = data[prop]["min"] || 0;
                            if (data[prop].default) {
                                element.value = data[prop].default;
                            }
                            p.classList.add('filter-container');
                        }
                        // Mehrfachauswahl
                        if (data[prop].type && data[prop].type == "multiple") {
                            p.textContent = data[prop].text;
                            // alle Optionen wandern in ein <ul>
                            element = document.createElement("ul");
                            element.classList.add('multipleInput');
                            element.classList.add(data[prop].class);

                            buttonRow.parentNode.insertBefore(element, buttonRow);
                            data[prop].options.forEach(function (d, index) {
                                var input = document.createElement("input"),
                                    label = document.createElement("label"),
                                    divChild = document.createElement('div'),
                                    divLabel = document.createElement('div'),
                                    li = document.createElement("li");
                                // <li> in <ul> einhängen
                                element.appendChild(li);
                                input.id = prop + "-" + index;
                                input.name = prop + "-" + index;
                                input.type = "checkbox";
                                input.value = data[prop].values[index];
                                li.appendChild(input);
                                label.htmlFor = prop + "-" + index;
                                label.textContent = " " + d;
                                divChild.classList.add("flex-row");
                                divChild.appendChild(input);
                                divChild.appendChild(label); // maybe label in input before div
                                li.appendChild(divChild);
                                if (data[prop].default && data[prop].default == d) {
                                    input.setAttribute("checked", "checked");
                                }
                            });
                            p.appendChild(element);
                            p.classList.add('filter-container');

                        }
                        // Mehrfachauswahl_Farben (Einzelauswahl)
                        if (data[prop].type && data[prop].type == "multipleColor") {
                            p.textContent = data[prop].text;
                            // alle Optionen wandern in ein <ul>
                            element = document.createElement("ul");
                            element.classList.add('multipleInput');
                            element.classList.add(data[prop].class);

                            buttonRow.parentNode.insertBefore(element, buttonRow);
                            data[prop].options.forEach(function (d, index) {
                                var input = document.createElement("input"),
                                    li = document.createElement("li"),
                                    label = document.createElement("label"),
                                    icon = document.createElement('i'),
                                    divChild = document.createElement('div');
                                // <li> in <ul> einhängen
                                element.appendChild(li);
                                icon.classList.add("circle");
                                icon.classList.add(d);
                                input.id = prop + "-" + index;
                                input.name = prop + "-" + index;
                                input.type = "checkbox";
                                input.value = d;
                                input.classList.add('color-input');
                                divChild.classList.add("flex-row");
                                divChild.appendChild(input);
                                label.htmlFor = input.id;
                                label.appendChild(icon);
                                divChild.appendChild(label);
                                li.appendChild(divChild);
                                if (data[prop].default && data[prop].default == d) {
                                    input.setAttribute("checked", "checked");
                                }
                            });
                            $('.color-input').on('change', function () {
                                $('.color-input').not(this).prop('checked', false);
                            });
                            p.appendChild(element);
                            p.classList.add('filter-container');

                        }
                        // Einfachauswahl
                        if (data[prop].type && data[prop].type == "select") {
                            // <label> als Kindelement für Beschriftung
                            p.appendChild(document.createElement("label"));
                            p.classList.add('filter-container');
                            p.lastChild.appendChild(document.createTextNode(data[prop].text + " "));
                            // alle Optionen wandern in ein <ul>
                            element = p.appendChild(document.createElement("select"));
                            element.classList.add('selectInput');
                            element.classList.add(data[prop].class);

                            element.name = prop;
                            data[prop].options.forEach(function (d, index) {
                                var o = document.createElement("option");
                                o.textContent = d;
                                o.value = data[prop].values[index];
                                element.appendChild(o);
                                if (data[prop].default && data[prop].default == data[prop].values[index]) {
                                    o.setAttribute("selected", "selected");
                                }
                            });
                        }
                        // Texteingabe
                        if (data[prop].type && data[prop].type == "text") {
                            // <label> als Kindelement für Beschriftung
                            p.appendChild(document.createElement("label"));
                            p.lastChild.appendChild(document.createTextNode(data[prop].text));
                            // alle Optionen wandern in ein <ul>
                            element = p.appendChild(document.createElement("input"));
                            element.classList.add('textInput');
                            element.classList.add('ui-autocomplete-input');
                            element.name = prop;

                            if (data[prop].default) {
                                element.textContent = data[prop].default;
                            }
                            p.classList.add(data[prop].class);
                            p.classList.add('filter-container');
                            if (data[prop].class === 'saveAsTagSelect') {
                                let hint = document.createElement('small');
                                hint.innerText = data[prop].hint;
                                p.appendChild(hint);

                                function split(val) {
                                    return val.split(/,\s*/);
                                }

                                function extractLast(term) {
                                    return split(term).pop();
                                }

                                $(element).autocomplete({
                                    minLength: 0,
                                    source: function (request, response) {
                                        ResourceRequester.get('tags', {
                                            locale: this.local,
                                            limit: 10,
                                            page: 1,
                                            searchFields: "name",
                                            search: extractLast(request.term),
                                            flat: true,
                                            action: 'filter'
                                        }).then(response);

                                        // $.getJSON(url, {
                                        //     search: extractLast(request.term),
                                        // }, response);
                                    },
                                    search: function () {
                                        var search = extractLast(this.value);

                                    },
                                    focus: function (event, ui) {
                                        return false;
                                    },
                                    select: function (event, ui) {
                                        var terms = split(this.value);
                                        terms.pop();
                                        terms.push(ui.item.value);
                                        terms.push("");
                                        this.value = terms.join(", ");
                                        // $('#generatePosts').attr("data-search-tag", this.value);
                                        return false;
                                    }
                                });

                                $(element).on('keydown', function (event) {
                                    if (event.keyCode === $.ui.keyCode.TAB && $(this).autocomplete("instance").menu.active) {
                                        event.preventDefault();
                                    }
                                })
                            }
                            if (data[prop].class === 'term') {
                                $(element).on("keydown", function (event) {
                                    if (event.keyCode === 13) {
                                        setTimeout(function () {
                                            $('button[name="search"]').trigger('click')
                                        }, 50);
                                    }
                                });
                            }
                        }
                    }
                    dialog.setCallback("cancel", cancel);
                    dialog.setCallback("ok", function () {
                        var result = {},
                            elements;
                        // Ergebnisse ermitteln
                        for (prop in data) {
                            elements = Array.prototype.slice.call(dialog.querySelectorAll(
                                '[name^="' + prop + '"]'));
                            if (data[prop].type && data[prop].type == "multiple") {
                                result[prop] = [];
                                elements.forEach(function (element) {
                                    if (element.checked) {
                                        result[prop].push(element.value);
                                    }
                                });
                            } else {
                                if (data[prop].type != "title" && data[prop].type != "info") {
                                    result[prop] = null;
                                    if (elements[0]) {
                                        result[prop] = elements[0].value;
                                    }
                                }
                            }
                        }
                        // Ergebnisse an die Callback-Funktion zurück geben
                        OK(result);
                    });
                    dialog.show();
                }
            }

            myDialog(
                // data
                {
                    title: {
                        text: translate('app.freepik.title'),
                        type: "title"
                    },
                    saveAsTag: {
                        text: translate('app.tag.saveAs'),
                        type: "text",
                        class: "saveAsTagSelect",
                        hint: translate('app.tag.hint'),
                    },
                    term: {
                        text: translate('app.term'),
                        type: "text",
                        class: "term"
                    },
                    premium: {
                        "default": "",
                        options: [
                            translate('app.license.both'),
                            translate('app.license.freemium'),
                            translate('app.license.premium'),
                        ],
                        values: [
                            "",
                            "freemium",
                            "premium",
                        ],
                        text: translate('app.license'),
                        type: "select",
                        class: "licenseSelect"
                    },
                    people: {
                        "default": translate('app.people.none'),
                        options: [
                            translate('app.people.none'),
                            translate('app.people.include'),
                            translate('app.people.exclude')
                        ],
                        values: [
                            "none",
                            "include",
                            "exclude",
                        ],
                        text: translate('app.people'),
                        type: "select",
                        class: "peopleSelect"
                    },
                    sex: {
                        "default": translate('app.sex.none'),
                        options: [
                            translate('app.sex.none'),
                            translate('app.sex.male'),
                            translate('app.sex.female')
                        ],
                        values: [
                            'none',
                            'male',
                            'female',
                        ],
                        text: translate('app.sex'),
                        type: "select",
                        class: "sexSelect"
                    },
                    age: {
                        "default": "",
                        options: [
                            translate('app.age.infant'),
                            translate('app.age.child'),
                            translate('app.age.teen'),
                            translate('app.age.young.adult'),
                            translate('app.age.adult'),
                            translate('app.age.senior'),
                            translate('app.age.elder'),
                        ],
                        values: [
                            "infant",
                            "child",
                            "teen",
                            "young-adult",
                            "adult",
                            "senior",
                            "elder",
                        ],
                        text: translate('app.age'),
                        type: "multiple",
                        class: "ageSelect"
                    },
                    ethnicity: {
                        "default": "",
                        options: [
                            translate('app.ethnicity.south-asian'),
                            translate('app.ethnicity.middle-eastern'),
                            translate('app.ethnicity.east-asian'),
                            translate('app.ethnicity.black'),
                            translate('app.ethnicity.hispanic'),
                            translate('app.ethnicity.indian'),
                            translate('app.ethnicity.white'),
                            translate('app.ethnicity.multiracial')
                        ],
                        values: [
                            "south-asian",
                            "middle-eastern",
                            "east-asian",
                            "black",
                            "hispanic",
                            "indian",
                            "white",
                            "multiracial"
                        ],
                        text: translate('app.ethnicity'),
                        type: "multiple",
                        class: "ethnicitySelect"
                    },
                    color: {
                        "default": "",
                        options: [
                            "red", "orange", "pink", "yellow", "green", "cyan",
                            "blue", "purple", "black", "gray", "white"
                        ],
                        text: translate('app.color'),
                        type: "multipleColor",
                        class: "colorSelect"
                    },
                },
                // OK
                function () {
                    // $('#saveImages').trigger('click');
                },
                // cancel
                function () {
                    let imageContainer = document.querySelector('.loaded-freepik-container');
                    this.page = 1;
                    this.term = "";
                    this.ethnicity = "";
                    this.age = "";
                    this.sex = "";
                    this.color = "";
                    this.people = "";
                    while (imageContainer.children.length > 0) {
                        imageContainer.children[0].remove();
                    }
                    const url = new URL(window.location.href);
                    url.searchParams.delete('searchFreepik');
                    window.history.pushState({}, document.title, window.location.pathname);
                }
            );
        }),
        type: 'button',
    });

    const {
        permissions: collectionPermissions = {},
        loading: collectionLoading,
        locked: collectionLocked,
    } = this.collectionStore;

    const addPermission = collectionPermissions.add !== undefined ? collectionPermissions.add : routeAddPermission;
    const deletePermission = collectionPermissions.delete !== undefined
        ? collectionPermissions.delete
        : routeDeletePermission;
    const editPermission = collectionPermissions.edit !== undefined ? collectionPermissions.edit : routeEditPermission;

    if (!collectionLocked && addPermission) {
        items.push({
            disabled: collectionLoading,
            icon: 'su-upload',
            label: translate('sulu_media.upload_file'),
            onClick: action(() => {
                this.showMediaUploadOverlay = true;
            }),
            type: 'button',
        });
    }

    if (deletePermission) {
        items.push({
            disabled: this.mediaListStore.selectionIds.length === 0,
            icon: 'su-trash-alt',
            label: translate('sulu_admin.delete_selected'),
            loading: this.mediaListStore.deletingSelection,
            onClick: this.appMediaList.requestSelectionDelete,
            type: 'button',
        });
    }

    if (!collectionLocked && editPermission) {
        items.push({
            disabled: this.mediaListStore.selectionIds.length === 0,
            icon: 'su-arrows-alt',
            label: translate('sulu_admin.move_selected'),
            onClick: action(() => {
                this.showMediaMoveOverlay = true;
            }),
            type: 'button',
        });
    }
    $(document).ready(function () {
        if (window.location.href.includes('?')) {
            let urlString = window.location.href;
            const UrlParams = new URLSearchParams(urlString.split('?')[1]);
            if (UrlParams.has('searchFreepik')) {
                param = UrlParams.get('searchFreepik');
            }
            //param
            if (param.length > 0) {
                $('.su-download').closest('button').get(0).click();
                $('#my-dialog').ready(function () {
                    $('input[name=saveAsTag]').val(param);
                    param = "";
                    // window.history.replaceState("", "Title", "/" + urlString.substring(urlString.lastIndexOf('/') + 1).split("?")[0]);
                    // $('button[name="search"]').get(0).click();
                    // console.log("click and load");
                });
            }
        }


        // Get the button:
        let button = document.getElementById("scrollButton");
        $(button).off('click').on('click', function () {
            // When the user clicks on the button, scroll to the top of the document
            $('#my-dialog').scrollTop(0);
        });

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function () {
            scrollFunction()
        };

        function scrollFunction() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                button.style.display = "block";
            } else {
                button.style.display = "none";
            }
        }
    });

    return {
        locale,
        disableAll: loading,
        backButton: this.collectionId.get()
            ? {
                onClick: () => {
                    this.clearLists();
                    router.restore(
                        COLLECTION_ROUTE,
                        {
                            id: this.collectionStore.parentId,
                            locale: this.locale.get(),
                            collectionPage: '1',
                        }
                    );
                },
            }
            : undefined,
        items,
        errors,
    };
});


/*
 * Image preview script
 * powered by jQuery (http://www.jquery.com)
 *
 * written by Alen Grakalic (http://cssglobe.com)
 *
 * for more info visit http://cssglobe.com/post/1695/easiest-tooltip-and-image-preview-using-jquery
 *
 */
function imagePreview() {
    /* CONFIG */
    const xOffset = 15;
    const yOffset = 30;

    // these 2 variable determine popup's distance from the cursor
    // you might want to adjust to get the right result
    let imageContainer = $('.freepik-container');
    var maxX = imageContainer.width();
    var maxY = imageContainer.height() - 100;

    /* END CONFIG */
    var callback = function (mouseEvent) {
        var thumbnail = $("#thumbnail");

        // top-right corner coords' offset
        var topRightCornerX = xOffset + thumbnail.width();
        var topRightCornerY = yOffset + thumbnail.height();

        topRightCornerY = Math.min(topRightCornerY + mouseEvent.pageY, maxY);

        if (mouseEvent.pageX > window.innerWidth / 2) {
            topRightCornerX = Math.min(topRightCornerX + mouseEvent.pageX, maxX + topRightCornerX);
            thumbnail
                .css("top", (topRightCornerY - thumbnail.height()) + "px")
                .css("left", (topRightCornerX - thumbnail.width() * 2 - xOffset * 2) + "px");
        } else {
            topRightCornerX = Math.min(topRightCornerX + mouseEvent.pageX, maxX);
            thumbnail
                .css("top", (topRightCornerY - thumbnail.height()) + "px")
                .css("left", (topRightCornerX - thumbnail.width()) + "px");
        }

    };
    let title = "";
    $(".thumbnail").hover(function (e) {
            maxX = $(document).width();
            maxY = $(document).height();
            title = $(this).find('img').get(0).title;
            var bottemText = (title !== "") ? "<br/>" + title : "";
            $("body").append("<p id='thumbnail'><img src='" + $(this).find('img').get(0).src + "' alt='Image thumbnail' />" + bottemText + "</p>");
            callback(e);
            $("#thumbnail").fadeIn("fast");
        },
        function () {
            $("#thumbnail").remove();
        }
    ).mousemove(callback);
}