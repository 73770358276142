import React from 'react';
import Button from 'sulu-admin-bundle/components/Button';
import type {FieldTypeProps} from 'sulu-admin-bundle/types';
import {action, observable} from "mobx";
import {translate} from 'sulu-admin-bundle/utils/Translator';
import {Dialog} from 'sulu-admin-bundle/components';
import $ from "jquery";
import './ButtonType.css';
import {ResourceRequester} from 'sulu-admin-bundle/services';

export default class ButtonType extends React.Component<FieldTypeProps<?string>> {
    @observable restoring: boolean = false;
    @observable id: boolean = false;
    @observable open: boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            restoring: false,
            editor: ""
        };
    }

    @action handleRestoreClick = (id: boolean) => {
        this.id = id;
    };

    @action handleDialogCancel = () => {
        this.id = false;
        this.prompts = undefined;
        this.setState({ open: this.state.open = false});
    };

    @action handleDialogConfirm = () => {
        let element = this;
        let editorText = "";
        if($('#holder').get(0).value.length > 0){
            editorText = $('#holder').get(0).value.trim();
        } else {
            editorText = $('.ck-content').get(0).innerText.trim();
        }
        let promp = document.getElementById("prompts").value + ": " + editorText;
        this.setState({ restoring: this.state.restoring = true})
        $.ajax({
            url: "/admin/api/ais/articles/gets/text?promp="+ encodeURIComponent(promp),
            beforeSend: function () {
                element.restoring = true;
            },
            success: function (data) {
                $('#holder').get(0).value = data;
            },
            complete: function () {
                element.restoring = false;
                element.setState({ open: element.state.open = false});
                element.setState({ restoring: element.state.restoring = false})
            }
        });
    };

    render() {
        return (
            <div>
                <Dialog
                    className={'dialog'}
                    cancelText={translate('sulu_admin.cancel')}
                    confirmLoading={this.restoring}
                    confirmText={translate('sulu_admin.ok')}
                    key="aigeneratorButton"
                    onCancel={this.handleDialogCancel}
                    onConfirm={this.handleDialogConfirm}
                    open={this.state.open}
                    title={translate("app.ai.generate.title")}
                    align={"center"}
                    confirmDisabled={false}
                    snackbarType={""}
                >
                    <div>{translate("app.ai.generate.body")}</div>
                    <div>{translate("app.ai.generate.promp")}</div>
                    <div><textarea cols={45} rows={10} name="prompts" id="prompts" onFocus={this.Autocompletion}/></div>
                    <div>{translate("app.ai.generate.tag")}</div>
                    <div><input width={"100%"} type="text" name="hashtags" id="hashtags"/></div>
                </Dialog>
                <div>
                    <Button
                        className={"btn btn-default"}
                        onClick={() => this.setState({ open: this.state.open = true})}
                        type={'button'}
                        active={false}
                        disabled={false}
                        loading={!!this.id}
                        showDropdownIcon={false}>
                        {translate('app.plugin.lable')}
                    </Button>
                    <button type={"button"} className={"btn"} onClick={() => $('#holder').get(0).value = ""}>
                        <span aria-label="su-trash-alt" className="su-trash-alt icon"></span>
                    </button>
                    <textarea name="holder" id="holder"/>
                </div>
            </div>
        );
    }

    Autocompletion() {
        let element = $('#prompts');
        function split(val) {
            return val.split(/,\s*/);
        }

        function extractLast(term) {
            return split(term).pop();
        }

        $(element).autocomplete({
            minLength: 0,
            source: function (request, response) {
                ResourceRequester.getList('aiPrompts', {
                    locale: this.local,
                    search: extractLast(request.term),
                    action: 'suggestion',
                    id: 0,
                }).then(response);
            },
            search: function () {
                var search = extractLast(this.value);

            },
            focus: function (event, ui) {
                return false;
            },
            select: function (event, ui) {
                var terms = split(this.value);
                terms.pop();
                terms.push(ui.item.value);
                terms.push("");
                this.value = terms.join(" ");
                return false;
            }
        });

        $(element).on('keydown', function (event) {
            if (event.keyCode === $.ui.keyCode.TAB && $(this).autocomplete("instance").menu.active) {
                event.preventDefault();
            }
        })
    }
}