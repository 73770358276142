// This file should only be changed by the `bin/console sulu:admin:update-build` command:
// See https://docs.sulu.io/en/latest/upgrades/upgrade-2.x.html

// Sulu Core Bundles
import {startAdmin} from 'sulu-admin-bundle';
import 'sulu-audience-targeting-bundle';
import 'sulu-category-bundle';
import 'sulu-contact-bundle';
import 'sulu-custom-url-bundle';
import 'sulu-location-bundle';
import 'sulu-media-bundle';
import 'sulu-page-bundle';
import 'sulu-preview-bundle';
import 'sulu-route-bundle';
import 'sulu-search-bundle';
import 'sulu-security-bundle';
import 'sulu-snippet-bundle';
import 'sulu-trash-bundle';
import 'sulu-website-bundle';
import {listItemActionRegistry} from 'sulu-admin-bundle/views';
import {listToolbarActionRegistry} from 'sulu-admin-bundle/views/List';
import {formToolbarActionRegistry} from 'sulu-admin-bundle/views/Form';
import {
    blockPreviewTransformerRegistry,
    listAdapterRegistry,
    fieldRegistry,
    viewRegistry,
    ckeditorPluginRegistry,
    ckeditorConfigRegistry
} from 'sulu-admin-bundle/containers';
// Add project specific javascript code and import of additional bundles to the following file:
import './app.js';
import aiTextGenerate from "./itemAction/aiTextGenerate";
import showChildrenText from "./itemAction/showChildrenText";
import createFreepikSearch from "./itemAction/createFreepikSearch";
import filterByButton from "./itemAction/filterByButton";
import showState from "./itemAction/showState";
import mediaOverview from "./views/mediaOverview/mediaOverview";
import ImportAsana from "./formToolbarAction/ImportAsana";

import ButtonType from "./Component/ButtonType";
import Blacklist from "./Component/BlacklistType";
const FIELD_TYPE_BUTTON_TYPE = 'button_type';
const FIELD_TYPE_BLACKLIST_TYPE = 'blacklist_type';

listItemActionRegistry.add('aiText', aiTextGenerate);
listItemActionRegistry.add('showChildrenText', showChildrenText);
listItemActionRegistry.add('showState', showState);
listItemActionRegistry.add('createFreepikSearch', createFreepikSearch);
listItemActionRegistry.add('filterByButton', filterByButton);

listToolbarActionRegistry.add('app.import_asana', ImportAsana);

viewRegistry.add('app.media.overview', mediaOverview);

ckeditorConfigRegistry.add((config) => ({
    toolbar: [...config.toolbar],
}));

fieldRegistry.add(FIELD_TYPE_BUTTON_TYPE, ButtonType);
fieldRegistry.add(FIELD_TYPE_BLACKLIST_TYPE, Blacklist);

// Start admin application
startAdmin();
