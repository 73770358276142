import React from "react";

import type {FieldTypeProps} from 'sulu-admin-bundle/types';
import {action, observable} from "mobx";
import $ from "jquery";
import Table from 'sulu-admin-bundle/components/Table'

export default class BlacklistType extends React.Component<FieldTypeProps<?string>> {
    constructor(props) {
        super(props);

        this.state = {
            rows:[],
            selectedRowIds:[],
        };
        let elemten = this;
        let tenant = ""
        if(window.location.href.search('add') < 0) {
            tenant = window.location.href.split('/details')[0].split('/tenants/de/')[1];
        }
        $.ajax({
            url: "/admin/api/app/blacklists?action=table&tenant="+tenant,
            beforeSend: function () {
            },
            success: function (data) {
                elemten.setState({rows: elemten.state.rows = Object.entries(data)});
            },
            complete: function () {
            }
        });
    }

    render() {
        const Header = Table.Header;
        const Body = Table.Body;
        const Row = Table.Row;
        const Cell = Table.Cell;
        const HeaderCell = Table.HeaderCell;

        const buttons = [{
            icon: 'su-sync',
            onClick: (rowId) => {
                let elemten = this;
                let tenant = ""
                if(window.location.href.search('add') < 0) {
                    tenant = window.location.href.split('/details')[0].split('/tenants/de/')[1];
                }
                $.ajax({
                    url: "/admin/api/app/blacklists/" + rowId + "?action=table&tenant="+ tenant,
                    type: 'DELETE',
                    beforeSend: function () {
                    },
                    success: function (data) {
                    },
                    complete: function () {
                        window.location.reload();
                    }
                });
            },
        }];

        return (
            <>
                <Table
                    selectMode="none"
                    buttons={buttons}
                    skin={'dark'}>
                    <Header>
                        <HeaderCell>Titel</HeaderCell>
                        <HeaderCell>Geplant am</HeaderCell>
                    </Header>
                    <Body>
                        {
                            this.state.rows.map((rowId, index) => {
                                return (
                                    <Row
                                        key={index}
                                        id={rowId[0]}>
                                        <Cell>{ rowId[1].title }</Cell>
                                        <Cell>{ rowId[1].plannedAt }</Cell>
                                    </Row>
                                )
                            })
                        }
                    </Body>
                </Table>
            </>
        );
    }
}
