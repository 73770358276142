// @flow
import React from 'react';
import {AbstractListItemAction} from 'sulu-admin-bundle/views';
import {action, observable} from 'mobx';
import {Dialog} from 'sulu-admin-bundle/components';
import {translate} from 'sulu-admin-bundle/utils/Translator';
import {ResourceRequester} from 'sulu-admin-bundle/services';
import type, {Node} from 'react';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/dist/themes/ui-darkness/jquery-ui.css';
import $ from "jquery";

export default class AiTextGenerate extends AbstractListItemAction {

    @observable id: ?string | number = undefined;
    @observable prompts: ?string | number = undefined;
    @observable restoring: boolean = false;

    @action handleRestoreClick = (versionId: string | number) => {
        this.id = versionId;
    };

    @action handleDialogCancel = () => {
        this.id = undefined;
        this.prompts = undefined;
    };

    @action handleDialogConfirm = () => {
        const {success_view: successView, repeat: repeat} = this.options;
        const {id, locale, webspace} = this.router.attributes;

        if (typeof successView !== 'string') {
            throw new Error('The "success_view" option cannot be null and must contain a string value!');
        }

        this.restoring = true;
        // console.log(this);
        ResourceRequester
            .get("baseArticle", {
                action: 'generateAiText',
                id: this.id,
                locale,
                webspace,
                prompts: document.getElementById("prompts").value,
                hashtags: document.getElementById("hashtags").value,
                repeats: document.getElementById("repeats").value
            }).then(action((response) => {
            this.restoring = false;
            this.id = undefined;
            this.router.redirect(successView, {id, locale, webspace});
        }));
    };

    getItemActionConfig(item: ?Object) {
        return {
            icon: 'su-shadow-page',
            onClick: item?.id ? () => this.handleRestoreClick(item.id) : undefined,
            disabled: !item?.id,
        };
    }

    getNode(): Node {
        return (
            <Dialog
                cancelText={translate('sulu_admin.cancel')}
                confirmLoading={this.restoring}
                confirmText={translate('sulu_admin.ok')}
                key="aigenerator"
                onCancel={this.handleDialogCancel}
                onConfirm={this.handleDialogConfirm}
                open={!!this.id}
                title={translate("app.ai.generate.title")}
            >
                <div>{translate("app.ai.generate.body")}</div>
                <div>{translate("app.ai.generate.promp")}</div>
                <div><textarea cols={45} rows={10} name="prompts" id="prompts" onFocus={this.Autocompletion} defaultValue={
                    "Erstelle mir aus dem Text einen sinnvollen Social Media Beitrag für Instragram und Facebook, ergänze ihn um sinnvolle Absätze, Hashtags und Emojis. Beachte dabei, das die Textlänge sich zwischen 140 und 200 Wörtern liegt."
                }/></div>

                <div>{"Wie viele Texte sollen generiert werden? (5)"}</div>
                <div><input width={"100%"} type="number" name="repeats" id="repeats"/></div>

                <div>{translate("app.ai.generate.tag")}</div>
                <div><input width={"100%"} type="text" name="hashtags" id="hashtags"/></div>
            </Dialog>
        );
    }

    Autocompletion() {
        let element = $('#prompts');
        function split(val) {
            return val.split(/,\s*/);
        }

        function extractLast(term) {
            return split(term).pop();
        }

        $(element).autocomplete({
            minLength: 0,
            source: function (request, response) {
                ResourceRequester.getList('aiPrompts', {
                    locale: this.local,
                    search: extractLast(request.term),
                    action: 'suggestion',
                    id: 0,
                }).then(response);
            },
            search: function () {
                var search = extractLast(this.value);

            },
            focus: function (event, ui) {
                return false;
            },
            select: function (event, ui) {
                var terms = split(this.value);
                terms.pop();
                terms.push(ui.item.value);
                terms.push("");
                this.value = terms.join(" ");
                return false;
            }
        });

        $(element).on('keydown', function (event) {
            if (event.keyCode === $.ui.keyCode.TAB && $(this).autocomplete("instance").menu.active) {
                event.preventDefault();
            }
        })
    }
}
