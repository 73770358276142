// @flow
import React from 'react';
import {AbstractListItemAction} from 'sulu-admin-bundle/views';
import {action, observable} from 'mobx';
import {ResourceRequester} from 'sulu-admin-bundle/services';

export default class createFreepikSearch extends AbstractListItemAction {
    @observable name: ?string | number = undefined;

    @action handleIdSetter = (name: string | number) => {
        this.name = name;
    };

    @action onClick = (clickTitle: string | number) => {
        this.name = clickTitle;
        const {success_view: successView} = this.options;
        const {locale, webspace} = this.router.attributes;
        this.router.redirect(successView, {locale, webspace, searchFreepik: this.name});
        return true;
    }

    getItemActionConfig(item: ?Object) {
        return {
            icon: 'su-search',
            onClick: item?.name ? () => this.onClick(item?.name) : undefined,
        };
    }
}