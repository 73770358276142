// @flow
import React from 'react';
import {AbstractListItemAction} from 'sulu-admin-bundle/views';
import {action, observable} from 'mobx';
import Style from '../itemActionCss/showState.scss';


export default class State extends AbstractListItemAction {
    @observable id: ?string | number = undefined;
    @observable approve: ?boolean | false;

    @action handleIdSetter = (id: string | number) => {
        this.id = id;
    };

    getItemActionConfig(item: ?Object) {
        if(item !== undefined){
            if (item?.approve) {
                return {
                    icon: 'su-check-circle green',
                    visibility: "visible",
                    disabled: !item?.id,
                    className: Style.showButton
                };
            }
            return {
                icon: 'su-ban yellow',
                disabled: !item?.id,
                className: Style.showButton
            };
        }
        return {
            icon: 'su-circle',
            disabled: !item?.id,
            className: Style.showButton
        };
    }
}