// @flow
import React from 'react';
import {AbstractListItemAction} from 'sulu-admin-bundle/views';
import {action, observable} from 'mobx';
import {ResourceRequester} from 'sulu-admin-bundle/services';

export default class childrenText extends AbstractListItemAction {
    @observable id: ?string | number = undefined;

    @action handleIdSetter = (id: string | number) => {
        this.id = id;
    };

    @action onClick = (clickid: string | number) => {
        this.id = clickid;
        const {success_view: successView} = this.options;
        const {locale, webspace} = this.router.attributes;
        ResourceRequester
            .get("baseArticle", {
                id: 1,
                action: 'search',
                locale,
                webspace,
                searchId: this.id
            }).then(action((response) => {
            this.router.redirect(successView, {locale, webspace, baseArticle: this.id});
        }));
        return true;
    }

    getItemActionConfig(item: ?Object) {
        return {
            icon: 'su-list-ul',
            onClick: item?.id ? () => this.onClick(item?.id) : undefined,
        };
    }
}