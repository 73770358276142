// @flow
import React from 'react';
import {AbstractListItemAction} from 'sulu-admin-bundle/views';
import {action, observable} from 'mobx';
import {ResourceRequester} from 'sulu-admin-bundle/services';

export default class filterByButton extends AbstractListItemAction {
    @observable id: ?string | number = undefined;

    @action handleIdSetter = (id: string | number) => {
        this.id = id;
    };

    @action onClick = (clickTitle: string | number) => {
        this.id = clickTitle;
        const {success_view: successView} = this.options;
        const {locale, webspace} = this.router.attributes;
        if(successView === "app.media.overview"){
            this.router.redirect(successView, {locale, webspace, "mediaFilter.tagId[0]": this.id});
        }
        if(successView === "app.ai_article.list_articles") {
            this.router.redirect(successView, {locale, webspace, "filter.tags[0]": this.id});
        }
        return true;
    }

    getItemActionConfig(item: ?Object) {
        const {icon: icon} = this.options;

        return {
            icon: icon,
            onClick: item?.id ? () => this.onClick(item?.id) : undefined,
        };
    }
}