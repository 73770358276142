// @flow
import React from 'react';
import jexl from 'jexl';
import {translate} from 'sulu-admin-bundle/utils/Translator';
import {ResourceFormStore, FormInspector} from 'sulu-admin-bundle/containers/Form';
import ResourceStore from 'sulu-admin-bundle/stores/ResourceStore';
import Form from 'sulu-admin-bundle/views/Form';
import Router from 'sulu-admin-bundle/services/Router';
import {ResourceRequester} from 'sulu-admin-bundle/services';
import AbstractFormToolbarAction from 'sulu-admin-bundle/views/Form/toolbarActions/AbstractFormToolbarAction';
import {action, observable} from "mobx";
import {IObservable} from "mobx";

export default class ImportAsana extends AbstractFormToolbarAction {
    @observable loading: boolean = false;

    constructor(
        resourceFormStore: ResourceFormStore,
        form: Form,
        router: Router,
        locales: ?Array<string>,
        options: { [key: string]: mixed },
        parentResourceStore: ResourceStore
    ) {
        super(resourceFormStore, form, router, locales, options, parentResourceStore);
        this.resourceFormStore = resourceFormStore;
        this.formInspector = new FormInspector(this.resourceFormStore);
        this.form = form;
        this.router = router;
        this.locales = locales;
        this.options = options;
        this.parentResourceStore = parentResourceStore;
    }

    @action handleDialogConfirm = () => {
        const {id, locale, webspace} = this.router.attributes;

        this.loading = true;
        ResourceRequester
            .get("baseArticle", {
                action: 'import',
                id: 0,
                locale,
            }).then(action((response) => {
            this.loading = false;
            // this.router.redirect('app.base_article.list_articles', {id, locale, webspace});
            window.location.reload();
        }));
    }

    getToolbarItemConfig() {

        return {
            icon: 'su-storage',
            label: translate('app.asana.import'),
            loading: this.loading,
            onClick: this.handleDialogConfirm,
            type: 'button',
        };
    }
}